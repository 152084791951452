<template>
  <v-container class="my-6">
      <v-row>
         <v-col cols="12" md="6" lg="6" xl="6">
          <div class="product_image">
            <div>
                <v-img class="mx-auto" width="300" height="auto" :src="singleProductData.img"></v-img>
            </div>
          </div>
         </v-col>
          <v-col cols="12" lg="6">
              <h1 class="mb-4">{{ singleProductData.title }}</h1>
              <h2 class="mb-4 primary--text">{{ singleProductData.price }}</h2>
              <p class="font-600 ml-auto mb-4">{{ singleProductData.description }}</p>
              <div class="d-flex mb-4">
                  <p class="grey--text text--grey-lighten-1 me-3 mb-0">Brand:</p>
                  <p class="font-600 mb-0">{{ singleProductData.brand }}</p>
              </div>
              <div class="d-flex mb-4">
                  <p class="grey--text text--grey-lighten-1 me-3 mb-0">Stock:</p>
                  <p v-if="singleProductData.amount > 0" class="font-600 mb-0">Available</p>
                  <p v-else class="font-600 mb-0">Unavailable</p>
              </div>
              <div class="mb-6 d-flex">
                  <div class="align-center d-flex">
                        <v-btn 
                            color="primary" 
                            outlined
                            icon
                            tile
                            class="br-8"
                            :disabled="singleProductData.qty == 0"
                            @click="singleProductData.qtyIndex--"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <h3 class="mx-5">{{singleProductData.qtyIndex}}</h3>
                        <v-btn 
                            color="primary" 
                            outlined
                            icon
                            tile
                            class="br-8"
                            @click="singleProductData.qtyIndex++"
                             
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </div>
                    <v-btn 
                        @click="addCartx(singleProductData)"
                        color="primary" 
                        class="text-capitalize d-flex product_add"
                    >
                        Add to Cart 
                    </v-btn>
              </div>
          </v-col>
      </v-row>
      <mobile-navigation />
  </v-container>
</template>

<script>
import { mapGetters, mapActions  } from 'vuex';
import CardCart from "@/components/cartCard/CardCart";
import MobileNavigation from '@/layouts/base/MobileNavigationBar'

export default {
    components: {
        CardCart,
        MobileNavigation
    },
    computed: {
      ...mapGetters(['getProducts']),
    },
    data(){
        return {
           tab: null,
           isQuantity: 0,
           singleProductData: {
                id: 1,
                img: require('@/assets/images/products/flash-1.png'),
                title: 'Sneakers',
                category: 'Sneakers',
                amount: 300,
                price: "1780$",
                qty: 0,
                qtyIndex: 1,
                brand: "Ziaomi",
                description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            },
        }
    },
    methods: {
        ...mapActions(["addCart"]),
        addCartx(data) {
            this.addCart(data)
        }
    }
}
</script>
